import { effect, signal } from '@preact/signals-react';
import { getStorage, setStorage } from '../../login/StoreData';


export const websocket = signal(undefined);
export const sendMessage = signal(undefined);
export const lastMessage = signal(undefined);
export const IswebsocketConnectionOpen = signal(false);
export const PasswordIsRight = signal(undefined);
export const verifiedUser = signal(undefined);
export const UserRole = signal(undefined);
export const UserId = signal(undefined);
export const RoomCode = signal(undefined);
export const ShowForAppParameterInURL = signal(undefined);
export const LastPresenterCodeAttempt = signal(undefined);
export const showLoadingScreen = signal(undefined);
export const nickName = signal(undefined);


export const DataFromServer = signal(undefined);
export const DataFromServerForChat = signal(undefined);

effect(() => { // nachdem man sich ein account gemacht hat(Anmeldung), wird es ausgelöst, falls man die seite aktualisiert, wird unter der effect ausgeführt
    if (!verifiedUser.value && PasswordIsRight.value && UserId.value !== undefined) {
        sendMessage.value(JSON.stringify({ opcode: 1, payload: UserId.peek() + "," + nickName.peek() }));
    }
})

export const setUserDataToServer = (userId, nickName) => {
    UserId.value = userId;
    sendMessage.value(JSON.stringify({ opcode: 1, payload: userId + "," + nickName }));
}

effect(() => {
    //   console.log("ServerMessage lastMessage", lastMessage.value, lastMessage)
    if (lastMessage.value !== null && lastMessage.value !== undefined) {
        let data = JSON.parse(lastMessage.value.data);
        // console.log("ServerMessage", data)
        switch (data.Content) {
            case "roompassword":
                if (data.passwordRight) {
                    var userId = getStorage("ikoCode");
                    var nickName = getStorage("nickName");
                    if (userId != undefined && nickName != undefined) {
                        setUserDataToServer(userId, nickName);
                    } else {
                        PasswordIsRight.value = true;
                        // const randomAlphaNumericUserID = generateRandomAlphaNumericUserID(5);
                        // const randomNicknName = "nickname_" + generateRandomAlphaNumericUserID(3);
                        // setStorage("nickName", randomNicknName, null);
                        // setStorage("userId", randomAlphaNumericUserID, null);
                        // sendMessage.value(JSON.stringify({ opcode: 1, payload: randomAlphaNumericUserID + "," + randomNicknName }));
                    }

                }else{
                    showLoadingScreen.value = false;
                }
                 console.log("roompassword ", data)
                break;
            case "login":
                // console.log("login bestätigt!!!!!!!!!", data)
                // PasswordIsRight.value = true;
                UserRole.value = data.userRole;
                UserId.value = data.userid;
                if (data.userRole === "Presenter")
                    RoomCode.value = data.password;

                setTimeout(() => {
                        verifiedUser.value = true;
                }, 500);
                break;
            case "pause":
                // console.log("pause bestätigt")
                break;
            case "umfrage":
                var surveyData = {
                    activeUserSurveyVoted: data.quest.NumberOfVotes,
                    maxActivUserSurvey: data.quest.NumberOfClients,
                    userAnswer: data.userAnswer,
                    blockUserInput: data.blockUserInput,
                    allowMultipleChoice: data.allowMultipleChoice,
                    surveyQuest: data.quest,
                };
                data = {
                    ...data,
                    group: data.group ? data.group : "Stream",
                    surveyData,
                };
                break;
            case "result":
                var surveyData = {
                    result: data.result,
                };
                data = {
                    ...data,
                    group: data.group ? data.group : "Stream",
                    surveyData,
                };
                break;
            case "ChatMessage":
                DataFromServerForChat.value = data;
                // console.log("peek ",DataFromServer.peek().Content)
                // data.Content = DataFromServer.peek().Content;
                return; // will nicht das DataFromServer gesetzt wird 
            default:
                break;
        }
        DataFromServer.value = data;
    }
})

