// App.js
import React, {  } from "react";
import { allStylesLoaded } from "../websocket/SignalContextFolder/CssContext";


const WaitForTheStylesToBeLoaded = () => {
    if (!allStylesLoaded.value)
        return null;

    return null; // TODO man kann ein Delay rein machen, von einer sek oder so und ein ladebalken anzeigen.
};

export default WaitForTheStylesToBeLoaded;