import React, { useEffect, useState } from "react";
import { Row, Col, Form, Button, Container } from "react-bootstrap";
import Header from "../shared/Header";
import { withRouter } from "react-router";
import { effect, signal } from "@preact/signals-react";
import { ShowForAppParameterInURL, sendMessage, setUserDataToServer as sendUserDataToServer } from "../websocket/SignalContextFolder/WebsocketContext";
import { getStorage, setStorage } from "./StoreData";
const showLoginMask = signal(false);
const LoginAsAppUser = (props) => {
    const [nickName, setNickName] = useState("");
    useEffect(() => {
        setTimeout(() => {
            var code = getCodeOrEmptyString();
            
            if (code !== null && code !== undefined) {
                setStorage("nickName", code, null);
                setStorage("userId", code, null);
                setStorage("ikoCode", code, null);
                //sendUserDataToServer(code, "N:" + code);
                showLoginMask.value = true;
                // window.location.reload(false);
                console.log("sende die werte zum server, als App")
            } else {
                ShowForAppParameterInURL.value = false;
            }
        }, 200);
    }, [])
    const renderUserId = () => {
        var userId = getStorage("userId");
        if (userId === null) {
            return <span className="special">...</span>;
        }
        return <span className="special">{userId}</span>;
    };
    const BestaetigungsFunc = () => {
        setStorage("nickName", nickName, null);
        sendUserDataToServer(getStorage("userId"), nickName);
        window.location.reload(false);

    };


    function getCodeOrEmptyString() {
        const query = new URLSearchParams(props.location.search);
        let code = query.get("iko");
        return code;
    }
    if (!showLoginMask.value)
        return;
    return (
        <div className="app-wrapper-login">
            <Header content={"anmeldung"} />
            <Container fluid="xl">
                {props.userRole != "Presenter" && (
                    <div>
                        <h2>Herzlich Willkommen beim ME-Berufe-Stream!</h2>
                        <br />
                        {/* <p>
                            Während des Streams kannst Du Inhalte speichern, die Dich interessieren.
                            Diese kannst Du später in der ME-Berufe App wieder aufrufen.
                            Dort erwarten Dich dann noch viele weitere Infos und spannende Challenges.
                        </p> */}
                        <div className="step-container">
                            <div className="step">
                                <p>Vergib Dir nun noch einen Nickname und bestätige die Eingabe.</p>
                            </div>
                            <div className="step-offset">
                                <Form.Control
                                    type="nickname"
                                    placeholder="Dein Nickname (max.15 Zeichen)"
                                    value={nickName}
                                    maxLength="15"
                                    pattern="[A-Z]*"
                                    onChange={(event) => setNickName(event.target.value.toUpperCase())}
                                />
                                <Form.Text className="text-muted">
                                    Maximal 15 Zeichen
                                </Form.Text>
                            </div>
                        </div>
                        <div className="nav">
                            <Button variant="primary" disabled={nickName.length === 0} type="submit" onClick={() => BestaetigungsFunc()}>
                                Bestätigen
                            </Button>
                        </div>
                    </div>
                )}
            </Container>
        </div>
    );

};
export default withRouter(LoginAsAppUser);