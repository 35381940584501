import config from "../config";

export async function sendBookmarkToServer(userid, bookmarkid) {
    return await fetch(config.bookmarkUrl + userid + "/bookmark/" + bookmarkid, {
        method: 'put'
    })
        .then((response) => response.json())
        .then((responseJson) => {
            return responseJson;
        })
        .catch((error) => {
            console.error(error);
        });
}