import React, { useEffect, useState } from "react";
import { withRouter } from "react-router";
import { effect, signal } from "@preact/signals-react";
import { LastPresenterCodeAttempt, sendMessage, socketMessage } from "../websocket/SignalContextFolder/WebsocketContext";

const PresenterLogin = (props) => {
    useEffect(() => {
        setTimeout(() => {
            var code = getCodeOrEmptyString();
            if (LastPresenterCodeAttempt.value !== code && code !== null && code !== undefined) {
                // console.log("SENDE CODE", code)
                LastPresenterCodeAttempt.value = code;
                sendMessage.value(JSON.stringify({ opcode: 1, payload: code }));
            } 
        }, 500);
    }, [])

    function getCodeOrEmptyString() {
        const query = new URLSearchParams(props.location.search);
        let code = query.get("login");
        return code;
    }

    return null;

};
export default withRouter(PresenterLogin);