import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import { images } from "../../websocket/SignalContextFolder/CssContext"; import { IswebsocketConnectionOpen, sendMessage } from '../../websocket/SignalContextFolder/WebsocketContext';
const debug = false;
const UmfrageResult = ({ surveyData, userRole }) => {
    const [result, setResult] = useState(surveyData.result);

    useEffect(() => {
        if (debug) {
            const maxNumber = 5;
            const debugResult = [];
            for (let i = 0; i < maxNumber; i++) {
                debugResult.push({ ClientID: "User " + i, Nickname: "\"Nickname " + (i + 1) + "\"", RightAnswer: (maxNumber - i - 1), Time: (i + 2) });
            }
            setResult(debugResult);
        }
    }, [debug, surveyData.result]);

    const onSwitchQuest = (directionName) => {
        if (userRole !== "Presenter") return;

        if (directionName === "pre") {
            try {
                if (!IswebsocketConnectionOpen.value) return;
                sendMessage.value(JSON.stringify({ opcode: 2002, payload: "" }))
            } catch (error) {
                console.log(error);
            }
        }
    };

    function getNumberAfterComma(miliseconds, quantity) {
        try {
            const minAsSting = ((miliseconds / 1000) % 60) + "";
            const numbers = minAsSting.split('.')[1].substring(0, quantity);
            return numbers;
        } catch (e) {

        }
        return "0";
    }

    function convertMiliseconds(miliseconds) {
        var days, hours, minutes, seconds, total_hours, total_minutes, total_seconds;

        const milisec = getNumberAfterComma(miliseconds, 2);
        total_seconds = parseInt(Math.floor(miliseconds / 1000));
        total_minutes = parseInt(Math.floor(total_seconds / 60));
        // total_hours = parseInt(Math.floor(total_minutes / 60));
        // days = parseInt(Math.floor(total_hours / 24));

        seconds = parseInt(total_seconds % 60);
        if (seconds < 10)
            seconds = "0" + seconds;
        minutes = parseInt(total_minutes % 60);
        // if (minutes < 10)
        //     minutes = "0" + minutes;
        // hours = parseInt(total_hours % 24);

        return minutes + ":" + seconds + "," + milisec;
    }

    const createList = () => {
        if (result == undefined)
            return null;
        return result.map(function (element, idx) {
            return (
                <div className='answer-item' key={idx}>
                    <div className='bulletpoint'>{idx + 1}</div>
                    <div
                    // mode="multi"
                    >
                        {element.Nickname}
                    </div>
                    <div
                    // mode="multi"
                    >
                        {element.RightAnswer}
                    </div>
                    <div
                    // mode="multi"
                    >
                        {convertMiliseconds(element.Time)}
                    </div>
                </div>
            );
        });
    };

    const tableHeader = () => {
        return (
            <div className="bestenliste-header">
                <div>Rang</div>
                <div>Nickname</div>
                <div>Richtige Antworten</div>
                <div>Gesamtzeit</div>
            </div>
        );
    };

    return (
        <div className="bestenliste" style={{ width: '100%', display: 'flex', alignSelf: 'center', alignContent: 'center' }}>
            <div style={{ width: '100%', height: '25%', position: 'relative' }}>
                <div className="content">
                    <div className="question" style={{ marginBottom: 30, justifyContent: 'center' }}>
                        <h1>Bestenliste</h1>
                    </div>
                    <div style={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', marginBottom: 20 }}>
                        {tableHeader()}
                    </div>
                    <div style={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        {createList()}
                    </div>
                </div>
                {userRole === "Presenter" && (
                    <div style={{ position: 'relative', marginTop: 60, marginLeft: window.innerWidth / 50 }}>
                        <Link to="/">
                            <img src={images.value["prev"]} style={{ cursor: 'hand' }} onClick={() => onSwitchQuest("pre")} />
                        </Link>
                    </div>
                )}
            </div>
        </div>
    );
};

export default UmfrageResult;