import React, { useState, useEffect, useRef } from "react";
import "animate.css";
import { DataFromServerForChat, sendMessage } from "../websocket/SignalContextFolder/WebsocketContext";
import { effect, signal } from "@preact/signals-react";
// import "../styles/scss/styles.scss";
var LastUser = "";
var LastMassage = "";
var MyLastMessage = "";
var OnScrollDownAfterAnimationTimer;
var scrollTolleranz = 50;
var lastMessage = {};
const messages = signal([
  {
    message: "Willkommen im TouchTomorrow-Stream!",
    username: "MINT-Coach",
    userRole: "Presenter",
  },
]);


effect(() => {
  var message = DataFromServerForChat.value;
  if (message === undefined || message.Content != "ChatMessage") return;
  if (!message || !message.Chatmessanges) return;
  if (Array.isArray(message.Chatmessanges)) {
    messages.value = [
      {
        id: 0,
        message: "Willkommen im TouchTomorrow-Stream!",
        username: "MINT-Coach",
        userRole: "Presenter",
      },
      ...message.Chatmessanges.map((m) => ({
        username: m.Name,
        id: m.Id,
        userRole: m.Role,
        message: m.Message,
      })),
    ];
  } else {
    messages.value = [
      ...messages.peek(),
      {

        username: message.Chatmessanges.Name,
        id: message.Chatmessanges.Id,
        userRole: message.Chatmessanges.Role,
        message: message.Chatmessanges.Message,
      },
    ];
  }
});

function Chat(props) {
  const [presenterName, setPresenterName] = useState("ME Team");
  var animation1 = "animate__animated animate__fadeInUp";
  var animation2 = "animate__animated animate__fadeOutDown";

  const [isOnBottom, setIsOnBottom] = useState(true);
  const [userScrollUpOrDown, setUserScrollUpOrDown] = useState(false);
  const [onTouch, setOnTouch] = useState(false);

  const [scrollHeight, setScrollHeight] = useState(0);
  const [scrollMaxHeight, setScrollMaxHeight] = useState(0);
  // User userScrollUpOrDown & onTouch
  const [userMoveInput, setUserMoveInput] = useState(false);
  const [showChat, setShowChat] = useState(false);
  const [showAnimation, setShowAnimation] = useState(animation1);
  const [text, setText] = useState("");
  const CheckScrollPosRef = useRef();
  const ScrollToBottomRef = useRef(null);
  const TextDivRef = useRef(new Array());
  const width = window.innerWidth;

  useEffect(() => {
    if (isOnBottom && showChat && !userMoveInput) scrollToBottom(10);
  }, [messages.value]);

  useEffect(() => {
    if (showChat) setTextOpacity(true);
  }, [scrollHeight]);


  const onScroll = (e) => {
    if (CheckScrollPosRef.current) {
      const { scrollTop, scrollHeight, clientHeight } =
        CheckScrollPosRef.current;

      if (scrollTop + clientHeight >= scrollHeight - scrollTolleranz) {
        if (!isOnBottom) {
          setIsOnBottom(true);
        }
      } else {
        if (isOnBottom) {
          if (userMoveInput) {
            setIsOnBottom(false);
          }
        }
      }
      setScrollHeight(scrollTop + clientHeight);
      setScrollMaxHeight(scrollHeight);
    }
  };
  const scrollToBottom = (time) => {
    if (showAnimation == animation2) return;
    clearTimeout(OnScrollDownAfterAnimationTimer);
    OnScrollDownAfterAnimationTimer = setTimeout(() => {
      if (ScrollToBottomRef.current)
        ScrollToBottomRef.current.scrollIntoView({ behavior: "smooth" });
    }, time);
  };
  const onEnterMessage = () => {
    if (text == "") {
      return;
    } else if (text == MyLastMessage && props.userRole != "Presenter") {
      setText("");
      return;
    }
    MyLastMessage = text;
    sendMessage.value(JSON.stringify({ opcode: 4001, payload: text }));
    setText("");
    scrollToBottom(100);
  };
  const BlockUser = (username, id) => {
    if (
      window.confirm(
        "Möchten Sie wirklich " + username + " (" + id + ")" + " blocken?"
      )
    ) {
      sendMessage.value(JSON.stringify({ opcode: 4002, payload: id }));
    }
  };
  const MapMassages = () => {
    TextDivRef.current = [];
    return messages.value.map((element, idx) => {
      var supportColorNameStyle = "chatItemWrapper";
      if (element.userRole === "Presenter") {
        supportColorNameStyle = "chatItemWrapperPresentor";
        element.username = presenterName;
      }
      if (element.id === undefined || element.id === "") return null;
      return (
        <div
          className={supportColorNameStyle}
          key={idx}
          ref={(element) => TextDivRef.current.push(element)}
        >
          <div className="chatItem">
            {(props.userRole === "Presenter" &&
              element.userRole !== "Presenter" && (
                <button
                  className="banned"
                  onClick={() => {
                    BlockUser(element.username, element.id);
                  }}
                >
                  {element.username + " (" + element.id + ")"}
                </button>
              )) || <div className="chatItemUser">{element.username}</div>}
            <div className="chatItemMessage">{element.message}</div>
          </div>
        </div>
      );
    });
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      onEnterMessage();
    }
  };
  const ChatFadeIn_Out = () => {
    if (showChat) {
      if (showAnimation == animation2) {
        setShowAnimation(animation1);
        return;
      }
      clearTimeout(OnScrollDownAfterAnimationTimer);
      setShowAnimation(animation2);
    } else {
      setShowChat(true);
      scrollToBottom(1000);
    }
  };

  const toggleChat = () => {
    setShowChat((s) => {
      scrollToBottom(800);
      return !s;
    });
  };

  const textOpacityEffect = () => {
    try {
      TextDivRef.current.map((item) => {
        try {
          const { scrollTop, clientTop } = CheckScrollPosRef.current;
          var yPosition = (item.offsetTop - scrollTop + clientTop) / 100;
          var minimumOpacity = 0.2;
          if (scrollTop == 0) {
            item.style.opacity = 1;
          } else if (yPosition >= -1 && yPosition < minimumOpacity) {
            item.style.opacity = minimumOpacity;
          } else if (yPosition > minimumOpacity && yPosition <= 1) {
            item.style.opacity = yPosition;
          } else {
            item.style.opacity = 1;
          }
        } catch (error) { }
      });
    } catch (error) { }
  };
  const setTextOpacity = (withDelay) => {
    if (withDelay) {
      setTimeout(() => {
        textOpacityEffect();
      }, 100);
    } else {
      textOpacityEffect();
    }
  };
  const handleTouch = (e, value) => {
    if (scrollHeight > scrollMaxHeight - scrollTolleranz)
      setUserMoveInput(value);
  };

  useEffect(() => {
    window.addEventListener("popstate", (e) => {
      e.preventDefault();
      if (showChat) toggleChat();
      window.history.pushState(null, null, `/${window.location.hash}`);
    });
  }, [showChat, toggleChat]);

  let chatClass = "chat-window closed";
  if (showChat) chatClass = "chat-window open";

  return (
    <div className="chatButtonWrapper">
      {!showChat && (
        <button className="chatButton" onClick={toggleChat}>
          <div className="chat-toggle-open-text">
            Chat einblenden
          </div>
        </button>
      )}
      <div className={chatClass}>
        <div className="chatWrapper">
          <div className="chat">
            <div className="chat-toggle" onClick={toggleChat}>
                Chat schließen
            </div>
            <div
              onScrollCapture={(e) => onScroll(e)}
              onTouchStart={(touchStartEvent) =>
                handleTouch(touchStartEvent, true)
              }
              onTouchEnd={(touchStartEvent) =>
                handleTouch(touchStartEvent, false)
              }
              onWheel={(e) => {
                if (e.nativeEvent.wheelDelta > 0) {
                  if (scrollHeight < scrollMaxHeight - scrollTolleranz)
                    setUserMoveInput(true);
                } else {
                  setUserMoveInput(false);
                }
              }}
              ref={CheckScrollPosRef}
              className="chat-container"
              style={{ overflowY: "scroll", height: "100%" }}
            >
              <div>{MapMassages()}</div>
              {showChat && <div ref={ScrollToBottomRef} />}
            </div>
            <div className="input-wrapper">
              <input
                maxLength="256"
                autoComplete="off"
                type="text"
                placeholder="Nachricht eingeben..."
                value={text}
                onChange={(e) => setText(e.target.value)}
                className="chatInput"
                onKeyPress={(e) => handleKeyPress(e)}
              />
              <button className="sendButton" onClick={onEnterMessage}>
                {">"}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Chat;
