


export async function GetCode(config) {
    console.log("useridURl", config.userIdUrl)
    return await fetch(config.userIdUrl, {
        method: 'GET'
    })
        .then((response) => response.json())
        .then((responseJson) => {
            return responseJson;
        })
        .catch((error) => {
            console.error(error);
        });
}

export async function PostUserId_Nickname(config, userId, nickname) {
    return await fetch(config.postUserIdNicknameUrl + userId + '/' + nickname, {
        method: 'Post'
    })
        .then((response) => response.json())
        .then((responseJson) => {
            return responseJson;
        })
        .catch((error) => {
            console.error(error);
        });
}