// import React, { Component } from "react";
import React, { useState } from 'react';
import TT_Template from './AnswerTemplateOptions/TT_Template';
import config from '../config';
import ME_Template from './AnswerTemplateOptions/ME_Template';
// import { Textfit } from 'react-textfit';
// -webkit-box-shadow: 0px 0px 5px 4px #4681cb; 
// box-shadow: 0px 0px 5px 4px #4681cb;
export default function AnswerTemplate(props) {
    const { active, isTrivia, isAnswerCorrect } = props;
    const percentage = props.percentage == undefined ? undefined : props.percentage[props.id];
    const onClick = props.onClick;
    const selected = props.selected

    // const getBulletStyle = () => {
    //     let base = {};      //Aussehen = btn-gears
    //     if (isTrivia) {
    //         if (isAnswerCorrect) {
    //             base = { backgroundColor: "rgb(64 206 124)" };
    //         }
    //     }
      
    //     return base;
    // }

    // const getEntireAnswerBox = (id) => {
    //     var abstand = 12;
    //     let base = {marginBottom:abstand};
    //     if (Array.isArray(selected))
    //         if (selected.includes(id))
    //             base = {
    //                 boxShadow: "0px 0px 5px 6px #0379A4",
    //                 marginBottom:abstand
    //             };
    //     return base;
    // }

    let prcntClass = 'percentage';
    if (active) prcntClass += ' active';

    if(config.appStyle === "tt"){
        return <TT_Template parantData={props}/>
    }else{
        return <ME_Template parantData={props}/>
    }
    // return (
    //     <div className="answer-item-wrapper" style={getEntireAnswerBox(props.id)}>
    //         <div className='bulletpoint' style={getBulletStyle()}>{props.bullet}</div>
    //         <div className='answer-item'

    //             onClick={() => onClick(props.id)}>
    //             <div className='answer-progressbar'>
    //                 <div className={prcntClass} style={{ width: percentage }}> </div>
    //             </div>
    //             <div className="answer-content"
    //                 style={{ height: '100%', paddingLeft:5,paddingBottom:10,paddingTop:5 }}
    //             >
    //                 <div style={{height: '100%', width:'100%'}}>
    //                     <div 
    //                         style={{ height: '100%', width: '100%',display:'flex',justifyContent:'flex-start', alignItems:"center"}}
    //                         // mode="multi"
    //                         // min={5}
    //                         // max={24}
    //                     >
    //                         {props.answer}
    //                     </div>
    //                 </div>
    //                 <span className='answer-percentage' style={{display:'flex', justifyContent:'flex-end',fontSize:12, width:50}}>
    //                     {percentage}
    //                 </span>
    //             </div>
    //         </div>
    //     </div >
    // )
}
