import React from 'react';
import { Row, Col } from "react-bootstrap";
import { images } from '../websocket/SignalContextFolder/CssContext';
import { RoomCode } from '../websocket/SignalContextFolder/WebsocketContext';
var isApp = undefined;

function Header(props) {

    const showHeader = () => {
        try {
            let paramString = window.location.href.split('?')[1];
            let query = new URLSearchParams(paramString);
            let code1 = query.get("noheader");
            if (code1 != null || code1 == "") {
                return false;
            }
            let code2 = query.get("iko");
            if (code2 != null || code2 == "") {
                return false;
            }
        } catch (error) {
            return true;
        }
        return true;
    }

    if (!showHeader()) return null;
    if (props.content === 'anmeldung') {
        return (
            <Row className="header-2">
                <Col className="title">
                    <img src={images.value["biglogo"]}
                    // style={{ width: 250, height: 250 }}
                    />
                </Col>
            </Row>
        )
    }

    function isIkoAvailable() {
        let paramString = window.location.href.split('?')[1];
        let query = new URLSearchParams(paramString);
        // console.log(query);
        let iko = query.get("iko");
        if (iko != undefined && iko != "") {
            isApp = true;
            return;
        }
        isApp = false;
    }

    if (isApp == undefined) {
        isIkoAvailable();
    }

    return (
        <Row className="header">
            <Col className="title">
                <img src={images.value["icon_white"]} />
                <h2>{props.group || 'Stream'}</h2>
            </Col>
            {
                !isApp &&
                <Col className="iko-code">
                    {/* <span>
                        {props.code ? props.code : 'LOGIN-CODE'}
                    </span> */}
                    <span>
                        {props.userRole === 'Presenter' && <>Das Passwort lautet: {RoomCode}</>}
                        {props.userRole !== 'Presenter' && (props.code ? props.code : 'CODE')}
                    </span>
                </Col>
            }
        </Row>
    )
}

export default Header;

