import React, { useState, useEffect, useRef } from "react";
import { Button, Row, Col } from "react-bootstrap";
import { sendBookmarkToServer } from "../../shared/Bookmark";
import { getStorage, setStorage } from "../../login/StoreData";
import { UserRole } from "../../websocket/SignalContextFolder/WebsocketContext";
import { dynamiConfig } from "../../websocket/SignalContextFolder/ConfigContext";
import { BigPlayButton, ClosedCaptionButton, ControlBar, FullscreenToggle, PlayToggle, Player, ProgressControl, ReplayControl, Shortcut, VolumeMenuButton } from 'video-react';
import 'video-react/dist/video-react.css';
function iOS() {
  var value = false;
  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
  const isIOS = /iPhone|iPad|iPod/i.test(navigator.userAgent);
  const isAndroid = /Android/i.test(navigator.userAgent);
  const isMac = navigator.platform.includes('Mac');
  if (isMobile) {
    if (isIOS) {
      value = true;
    } else if (isAndroid) {
      value = false;
    }
  } else if (isMac) {
    value = true;
  } else {
    value = false;
  }
  return value;
}
var fertig = false;
function Movie({ data, sendMessage }) {
  let props = data;
  const [saved, setSave] = useState(
    getStorage(`bookmark:${props.bookmarkId}`) === "saved"
  );

  const [muted, setMuted] = useState(true);
  const player = useRef(null);
  const dontPlayVideo = () => {
    try {
      let paramString = window.location.href.split("?")[1];
      let query = new URLSearchParams(paramString);
      let code = query.get("novideo");
      if (code != null || code == "") {
        return true;
      }
    } catch (error) {
      return false;
    }
    return false;
  };

  const bookmark = () => {
    if (saved) {
      return;
    }
    var element = document.querySelector(".btn-bookmark");
    element.classList.remove("bounce");
    setTimeout(() => {
      element.classList.add("bounce");
    }, 100);
    const temp = async () => {
      var response = await sendBookmarkToServer(
        getStorage("userId"),
        props.bookmarkId
      );
      if (response !== undefined || true) {
        setStorage("bookmark:" + props.bookmarkId, "saved", null);
        setSave(true); // State setzten und etwas mit den button machen
      }
    };
    try {
      window.parent.postMessage({ event: "refresh-bookmarks" }, "*");
    } catch (error) { }
    temp();
  };
  useEffect(() => {
    if (player.current != null)
      startPlayerAfterLoad();
  }, [player.current])

  const startPlayerAfterLoad = () => {
    const message = props;
    const { player: localPlayer } = player.current.getState();
    if (message.playVideo) {
      player.current.seek(message.playTime + 1);
      player.current.play()
    }
  }

  function handlePauseEvent() {
    sendMessage(JSON.stringify({
      opcode: 2012,
      payload: "movie",
      playVideo: false,
    }));
  }
  function handlePlayEvent() {
    const { player: localPlayer } = player.current.getState();
    try {
      sendMessage(JSON.stringify({
        opcode: 2011,
        payload: "movie",
        playVideo: true,
        playTime: localPlayer.currentTime,
      }));
    } catch (error) {
      console.log(error);
    }

  }

  function handleSeekEvent() {
    const { player: localPlayer } = player.current.getState();
    try {
      sendMessage(JSON.stringify({
        opcode: 2011,
        payload: "movie",
        playVideo: false,
        playTime: localPlayer.currentTime,
      }));
      if (UserRole.value === "Presenter")
        pauseVideo();
    } catch (error) {
      console.log(error);
    }
  }
  useEffect(() => {
    const message = props;
    if (message.Content != "movie") return;
    if (player.current != null) {
      const newTime = Math.round(message.playTime);
      console.log("newTime ", newTime, player.current)
      if (player.current.seek != null)
        if (message.playVideo) {
          player.current.seek(newTime);
          playVideo();
        } else {
          player.current.seek(newTime);
          pauseVideo();
        }
    }
  }, [props])

  useEffect(() => {
    try {
      window.addEventListener(
        "resize",
        function () {
        },
        false
      );

    } catch (error) {
      console.log("Fehler beim setzen der größe, wenn man fullscreen mode aktiviert und deaktiviert")
    }
  }, [])

  const playVideo = () => {
    console.log("play video")
    player.current.play();
  }

  const pauseVideo = () => {
    console.log("pause video")
    player.current.pause();
  }
  const handleVideoEnded = () => {
    if (player === null)
      return;
    const { player: localPlayer } = player.current.getState();
    console.log(localPlayer.isFullscreen)
    if (localPlayer.isFullscreen) {
      player.current.toggleFullscreen();
    }
  }
  const toggleMutedVideo = () => {
    player.current.muted = !player.current.muted;
    setMuted(player.current.muted);
  }

  useEffect(() => {
    const handleOrientationChange = () => {
      const { player: localPlayer } = player.current.getState();
      const isFullscreen = localPlayer.isFullscreen;
      const isLandscape = window.screen.orientation.type.includes('landscape');
      if (isFullscreen && isLandscape) {
        if (localPlayer) {
          localPlayer.toggleFullscreen(); // Verlassen des Vollbildmodus
        }
      }
    };

    window.addEventListener('orientationchange', handleOrientationChange);

    return () => {
      window.removeEventListener('orientationchange', handleOrientationChange);
    };
  }, []); // Füge eine leere Abhängigkeitsliste hinzu, um sicherzustellen, dass der Event-Listener nur einmal hinzugefügt und entfernt wird

  return (
    <Row key={props.mov}>
      <Col>
        <h2>{props.title}</h2>
        <div className="video">
          {dynamiConfig.value.showBookmarkButton && props.bookmarkId && UserRole.value !== "Presenter" && (
            <div
              className={"btn-bookmark " + (saved ? "btn-saved" : "btn-save")}
              onClick={saved ? undefined : () => bookmark()}
            >
              <div className="btn-save-label">Speichern</div>
              <svg
                className="save"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="-247 341 116 112"
              >
                <path d="M-224.74 356.616l-16.827 46.233c-.616 1.69-.224 3.536.878 4.895l31.524 37.652c1.102 1.36 2.98 2.043 4.7 1.71l48.315-8.487c1.717-.332 3.203-1.494 3.82-3.185l16.826-46.233c.616-1.692.224-3.537-.878-4.896l-31.523-37.652c-1.102-1.36-2.98-2.043-4.7-1.71l-48.315 8.486c-1.718.333-3.204 1.494-3.82 3.186z"></path>
              </svg>
            </div>
          )}

          {dontPlayVideo() ? (
            <div className="novideo-container">
              <div>Das Video wird vorne abgespielt!</div>
            </div>
          ) :
            <div >
              <Player
                playsInline={true}
                playsinline={true}
                webkit-playsinline={true}
                ref={player}
                muted
                src={props.mov}
                onPause={() => handlePauseEvent()}
                onPlay={() => handlePlayEvent()}
                onSeeking={() => handleSeekEvent()}
                onEnded={() => handleVideoEnded()}
              >
                <Shortcut
                  clickable={UserRole.value === "Presenter"}
                  dblclickable={false}
                />
                <BigPlayButton className={UserRole.value !== "Presenter" ? "videoPlayButtonNichtAnzeigen" : ""} />
                <ControlBar
                  disableDefaultControls={false}
                  disableCompletely={UserRole.value !== "Presenter"}
                  className="my-class" >
                  {
                    UserRole.value === "Presenter" &&
                    <PlayToggle />
                  }
                  {
                    UserRole.value === "Presenter" &&
                    <VolumeMenuButton></VolumeMenuButton>
                  }
                  {
                    UserRole.value === "Presenter" &&
                    <ProgressControl />
                  }
                  <div style={{ marginLeft: 'auto' }}></div>
                  <FullscreenToggle />
                </ControlBar>
              </Player>
            </div>
          }
          <div className="center-bt">
            {!dontPlayVideo() && (
              <div>
                {UserRole.value == "Presenter" && (
                  <Button
                    style={{ marginRight: 10 }}
                    onClick={() => {
                      if (player.current === null)
                        return;
                        player.current.seek(0)
                        player.current.play();
                    }}
                  >
                    <svg
                      aria-hidden="true"
                      focusable="false"
                      data-prefix="fas"
                      data-icon="reply"
                      class="svg-inline--fa fa-reply fa-w-16"
                      role="img"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 512 512"
                    >
                      <path
                        fill="currentColor"
                        d="M8.309 189.836L184.313 37.851C199.719 24.546 224 35.347 224 56.015v80.053c160.629 1.839 288 34.032 288 186.258 0 61.441-39.581 122.309-83.333 154.132-13.653 9.931-33.111-2.533-28.077-18.631 45.344-145.012-21.507-183.51-176.59-185.742V360c0 20.7-24.3 31.453-39.687 18.164l-176.004-152c-11.071-9.562-11.086-26.753 0-36.328z"
                      ></path>
                    </svg>
                  </Button>
                )}
                {/* <Button
                  style={{ marginRight: 10 }}
                  onClick={() => {
                    player.toggleFullscreen();
                  }}
                >
                  <svg
                    aria-hidden="true"
                    focusable="false"
                    data-prefix="fas"
                    data-icon="expand"
                    className="svg-inline--fa fa-expand fa-w-14"
                    role="img"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 448 512"
                  >
                    <path
                      fill="currentColor"
                      d="M0 180V56c0-13.3 10.7-24 24-24h124c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12 12H64v84c0 6.6-5.4 12-12 12H12c-6.6 0-12-5.4-12-12zM288 44v40c0 6.6 5.4 12 12 12h84v84c0 6.6 5.4 12 12 12h40c6.6 0 12-5.4 12-12V56c0-13.3-10.7-24-24-24H300c-6.6 0-12 5.4-12 12zm148 276h-40c-6.6 0-12 5.4-12 12v84h-84c-6.6 0-12 5.4-12 12v40c0 6.6 5.4 12 12 12h124c13.3 0 24-10.7 24-24V332c0-6.6-5.4-12-12-12zM160 468v-40c0-6.6-5.4-12-12-12H64v-84c0-6.6-5.4-12-12-12H12c-6.6 0-12 5.4-12 12v124c0 13.3 10.7 24 24 24h124c6.6 0 12-5.4 12-12z"
                    ></path>
                  </svg>
                </Button> */}
              </div>
            )}
            {!dontPlayVideo() && (
              <Button
                onClick={() => {
                  console.log("toggle")
                  toggleMutedVideo();
                }}
              >
                {muted ? (
                  <>
                    <svg
                      aria-hidden="true"
                      focusable="false"
                      data-prefix="fas"
                      data-icon="volume-mute"
                      className="svg-inline--fa fa-volume-mute fa-w-16"
                      role="img"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 512 512"
                    >
                      <path
                        fill="currentColor"
                        d="M215.03 71.05L126.06 160H24c-13.26 0-24 10.74-24 24v144c0 13.25 10.74 24 24 24h102.06l88.97 88.95c15.03 15.03 40.97 4.47 40.97-16.97V88.02c0-21.46-25.96-31.98-40.97-16.97zM461.64 256l45.64-45.64c6.3-6.3 6.3-16.52 0-22.82l-22.82-22.82c-6.3-6.3-16.52-6.3-22.82 0L416 210.36l-45.64-45.64c-6.3-6.3-16.52-6.3-22.82 0l-22.82 22.82c-6.3 6.3-6.3 16.52 0 22.82L370.36 256l-45.63 45.63c-6.3 6.3-6.3 16.52 0 22.82l22.82 22.82c6.3 6.3 16.52 6.3 22.82 0L416 301.64l45.64 45.64c6.3 6.3 16.52 6.3 22.82 0l22.82-22.82c6.3-6.3 6.3-16.52 0-22.82L461.64 256z"
                      ></path>
                    </svg>
                  </>
                ) : (
                  <>
                    <svg
                      aria-hidden="true"
                      focusable="false"
                      data-prefix="fas"
                      data-icon="volume-up"
                      className="svg-inline--fa fa-volume-up fa-w-18"
                      role="img"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 576 512"
                    >
                      <path
                        fill="currentColor"
                        d="M215.03 71.05L126.06 160H24c-13.26 0-24 10.74-24 24v144c0 13.25 10.74 24 24 24h102.06l88.97 88.95c15.03 15.03 40.97 4.47 40.97-16.97V88.02c0-21.46-25.96-31.98-40.97-16.97zm233.32-51.08c-11.17-7.33-26.18-4.24-33.51 6.95-7.34 11.17-4.22 26.18 6.95 33.51 66.27 43.49 105.82 116.6 105.82 195.58 0 78.98-39.55 152.09-105.82 195.58-11.17 7.32-14.29 22.34-6.95 33.5 7.04 10.71 21.93 14.56 33.51 6.95C528.27 439.58 576 351.33 576 256S528.27 72.43 448.35 19.97zM480 256c0-63.53-32.06-121.94-85.77-156.24-11.19-7.14-26.03-3.82-33.12 7.46s-3.78 26.21 7.41 33.36C408.27 165.97 432 209.11 432 256s-23.73 90.03-63.48 115.42c-11.19 7.14-14.5 22.07-7.41 33.36 6.51 10.36 21.12 15.14 33.12 7.46C447.94 377.94 480 319.54 480 256zm-141.77-76.87c-11.58-6.33-26.19-2.16-32.61 9.45-6.39 11.61-2.16 26.2 9.45 32.61C327.98 228.28 336 241.63 336 256c0 14.38-8.02 27.72-20.92 34.81-11.61 6.41-15.84 21-9.45 32.61 6.43 11.66 21.05 15.8 32.61 9.45 28.23-15.55 45.77-45 45.77-76.88s-17.54-61.32-45.78-76.86z"
                      ></path>
                    </svg>
                  </>
                )}
              </Button>
            )}
          </div>
        </div>
        <br />
        {props.caption && props.caption.length > 0 && (
          <>
            <h2>Fast Facts:</h2>
            <div className="fact-box">
              {props.caption.map((element, idx) => {
                return <span key={idx}>{element}</span>;
              })}
            </div>
          </>
        )}
      </Col>
    </Row >
  );
}

export default Movie;
