// App.js
import React, { useEffect, useState } from "react";

import "./style/css/globalLoad.scss"
// import Websocket from "./websocket/Websocket";
import AfterLoad from "./AfterLoad";
import Websocket from "./websocket/Websocket";
import { IswebsocketConnectionOpen, websocket } from "./websocket/SignalContextFolder/WebsocketContext";
import WaitForTheStylesToBeLoaded from "./component/WaitForTheStylesToBeLoaded";
import { allStylesLoaded } from "./websocket/SignalContextFolder/CssContext";
import { dynamiConfig } from "./websocket/SignalContextFolder/ConfigContext";

import ConnectionLost from "./component/ConnectionLost";
import WebsocketWrapper from "./websocket/Websocket";
import Pausenbild from "./switch/pages/Pausenbild";
import { withRouter } from "react-router";
import { getParams, roomNotFound } from "./websocket/SignalContextFolder/URLSearchParamsContext";
import RoomNotFoundClass from "./switch/pages/RoomNotFoundClass";
// import "../node_modules/video-react/dist/video-react.css";
// import "./style/css/test.css";
// import { websocketConnection } from "./websocket/Websocket";
const BeforWebsocket = (props) => {
  var room = getParams(props.location.search, "room")
  if (room === null) {
    roomNotFound.value = true;
    console.log("room not found")
    return <RoomNotFoundClass></RoomNotFoundClass>
  } else {
    roomNotFound.value = false;
  }
}
const App = (props) => {


  if (roomNotFound.value) {
    return <div id="root-box">
      {
        BeforWebsocket(props)
      }

    </div>
  }
  else {
    return (
      <div id="root-box">
        <Websocket />
        {
          IswebsocketConnectionOpen.value &&
          <div>
            {
              !allStylesLoaded.value &&
              <WaitForTheStylesToBeLoaded />
              ||
              <AfterLoad />
            }
          </div>
          ||
          <Pausenbild></Pausenbild>
        }
      </div>
    );
  }
};

export default withRouter(App);;