import React, { useState, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import { sendBookmarkToServer } from "../../shared/Bookmark";
import { getStorage, setStorage } from "../../login/StoreData";
import { FullScreen, useFullScreenHandle } from "react-full-screen";
// import { ReactComponent as CloseSvg } from "../styles/svg/close.svg";
// import "./css/Picture.css";
import { DataFromServer, UserRole } from "../../websocket/SignalContextFolder/WebsocketContext";
import { images } from "../../websocket/SignalContextFolder/CssContext";
import { dynamiConfig } from "../../websocket/SignalContextFolder/ConfigContext";

function Picture() {
  const props = DataFromServer.peek();
  console.log(props)
  const [saved, setSave] = useState(
    getStorage(`bookmark:${props.bookmarkId}`) === "saved"
  );
  const handle = useFullScreenHandle();

  const bookmark = () => {
    if (saved) {
      return;
    }
    var element = document.querySelector(".btn-bookmark");
    element.classList.remove("bounce");
    setTimeout(() => {
      element.classList.add("bounce");
    }, 100);
    const temp = async () => {
      var response = await sendBookmarkToServer(
        getStorage("userId"),
        props.bookmarkId
      );
      if (response !== undefined || true) {
        setStorage("bookmark:" + props.bookmarkId, "saved", null);
        setSave(true); // State setzten und etwas mit den button machen
      }
    };
    try {
      window.parent.postMessage({ event: "refresh-bookmarks" }, "*");
    } catch (error) { }
    temp();
  };
  let classnameFullscreen = "";
  if (handle.active) {
    classnameFullscreen = "Center";
  }
  console.log("dynamiConfig.value.showBookmarkButton",dynamiConfig.value.showBookmarkButton)
  return (
    <Row>
      <Col>
        <h2>{props.title}</h2>
        <div className="picture">
          {dynamiConfig.value.showBookmarkButton && props.bookmarkId && UserRole.value !== "Presenter" && (
            <div
              className={"btn-bookmark " + (saved ? "btn-saved" : "btn-save")}
              onClick={saved ? undefined : () => bookmark()}
            >
              <div className="btn-save-label">Speichern</div>
              <svg
                class="save"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="-247 341 116 112"
              >
                <path d="M-224.74 356.616l-16.827 46.233c-.616 1.69-.224 3.536.878 4.895l31.524 37.652c1.102 1.36 2.98 2.043 4.7 1.71l48.315-8.487c1.717-.332 3.203-1.494 3.82-3.185l16.826-46.233c.616-1.692.224-3.537-.878-4.896l-31.523-37.652c-1.102-1.36-2.98-2.043-4.7-1.71l-48.315 8.486c-1.718.333-3.204 1.494-3.82 3.186z"></path>
              </svg>
            </div>
          )}

          <FullScreen handle={handle}>
            {handle.active && (
              <div className="close-wrapper"> 
                <div className="close-button" style={{ cursor: "hand" }}>
                  <img src={images.value["close"]} />
                  {/* <CloseSvg onClick={handle.exit} /> */}
                </div>
              </div>
            )}
            <div
              className={classnameFullscreen}
              onClick={(!handle.active && handle.enter) || handle.exit}
            >
              <img src={props.picture} alt="Grafik" />
            </div>
          </FullScreen>
        </div>
        <br />
        {props.caption && props.caption.length > 0 && (
          <>
            <h2>Fast Facts:</h2>
            <div className="fact-box">
              {props.caption.map((element, idx) => {
                return <span key={idx}>{element}</span>;
              })}
            </div>
          </>
        )}
      </Col>
    </Row>
  );
}

export default Picture;
