// return <Umfrage userRole={UserRole.value} surveyData={props.surveyData} surveyCountdown={props.surveyCountdown} maxSurveyCountdownTimer={props.maxSurveyCountdownTimer} />

import { useEffect, useState } from "react";
import { IswebsocketConnectionOpen, sendMessage } from "../../websocket/SignalContextFolder/WebsocketContext";
import { Col, Row } from "react-bootstrap";
import { images } from "../../websocket/SignalContextFolder/CssContext";
import Countdown from "../../shared/Countdown";
import AnswerTemplate from "../../component/AnswerTemplate";

function Umfrage({ userRole, surveyData, surveyCountdown, maxSurveyCountdownTimer }) {

    const [active, setActive] = useState(false);
    const [buttonId, setButtonId] = useState(surveyData.userAnswer || []);
    const [serverPayload, setServerPayload] = useState("");
    const [answerLogged, setAnswerLogged] = useState(false);
    const [allowMultipleChoice, setAllowMultipleChoice] = useState();
    // sendMessage.value(JSON.stringify({ opcode: 0, payload: password }));
    console.log("surveyData ", surveyData)
    useEffect(() => {
        setButtonId(surveyData.userAnswer || []);
    }, [surveyData])
    const onButtonSelected = (buttonID) => {
        console.log("onButtonSelected", buttonID, userRole)
        if (userRole == "Presenter") return;
        if (
            surveyData.surveyQuest.BlockAppInput ||
            answerLogged ||
            surveyData.blockUserInput
        ) {
            return;
        }
        try {
            if (!IswebsocketConnectionOpen.value) return;

            if (
                allowMultipleChoice ||
                surveyData.allowMultipleChoice
            ) {
                var buttonIds = [];
                if (buttonId != null && buttonId != undefined) {
                    buttonIds = buttonId;
                }
                if (buttonIds.includes(buttonID)) {
                    buttonIds = buttonIds.filter((e) => e !== buttonID);
                } else {
                    buttonIds.push(buttonID);
                }
                var serverArray = "";
                buttonIds.forEach((element) => {
                    serverArray += element + ",";
                });
                serverArray = serverArray.substring(0, serverArray.length - 1);
                setButtonId(buttonIds);
                setServerPayload(serverArray);
                console.log("set allowMultipleChoice values")
            } else {
                setButtonId([buttonID]);
                setServerPayload(buttonID + "");
                console.log("set one value")
            }
        } catch (error) {
            console.log(error);
        }
    };
    const onUserLoggedAnswer = () => {
        if (
            surveyData.surveyQuest.BlockAppInput ||
            answerLogged ||
            buttonId == [] ||
            buttonId === null ||
            buttonId === undefined ||
            surveyData.blockUserInput ||
            buttonId.length === 0
        ) {
            console.log(">>>>>>>>>>>>>>>>> mach return", buttonId)
            return;
        }
        console.log(">>>>>>>>>>>>>>>>>", buttonId)
        try {
            if (!IswebsocketConnectionOpen) return;
            setAnswerLogged(true);
            console.log("sende an server ", serverPayload)
            sendMessage.value(JSON.stringify({ opcode: 3001, payload: serverPayload }))
        } catch (error) {
            console.log(error);
        }
    };
    const onStopTheCount = () => {
        if (userRole != "Presenter") return;
        if (surveyData.surveyQuest.BlockAppInput) return;
        try {
            if (!IswebsocketConnectionOpen) return;
            sendMessage.value(JSON.stringify({ opcode: 2001, payload: "" }))
        } catch (error) {
            console.log(error);
        }
    };
    const showResultScreen = () => {
        if (userRole != "Presenter") return;
        console.log(surveyData.surveyQuest.BlockAppInput)
        // if (!surveyData.surveyQuest.BlockAppInput) return;
        try {
            if (!IswebsocketConnectionOpen) return;
            sendMessage.value(JSON.stringify({ opcode: 2005, payload: "" }))
        } catch (error) {
            console.log(error);
        }
    };
    const onSwitchQuest = (directionName) => {
        if (userRole != "Presenter") return;
        if (directionName === "pre") {
            try {
                if (!IswebsocketConnectionOpen) return;
                sendMessage.value(JSON.stringify({ opcode: 2002, payload: "" }))
            } catch (error) {
                console.log(error);
            }
        } else if (directionName === "next") {
            try {
                if (!IswebsocketConnectionOpen) return;
                sendMessage.value(JSON.stringify({ opcode: 2003, payload: "" }))
            } catch (error) {
                console.log(error);
            }
        }
    };
    // const onStartCountdown = () => {
    //     try {
    //         if (!IswebsocketConnectionOpen) return;
    //         sendMessage.value(JSON.stringify({ opcode: 2004, payload: "" }))
    //     } catch (error) {
    //         console.log(error);
    //     }
    // };

    const showWhitePage = (() => {
        return (
            <Row>
                <Col>
                    <div className="content" style={{ marginTop: 15 }}>
                        <div className="question" style={{ width: "100%" }}>
                            <div style={{ width: "100%", height: 100 }}>
                                <div
                                    style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        textAlign: "center",
                                        height: "100%",
                                        width: "100%",
                                    }}
                                //   min={8}
                                //   max={25}
                                //   mode="multi"
                                >
                                    <strong>Gleich geht es mit der nächsten Frage weiter!</strong>
                                </div>
                            </div>
                        </div>
                    </div>
                    {userRole == "Presenter" && (
                        <div className="footer">
                            <img src={images.value["pre"]} style={{ cursor: 'hand' }} onClick={() => onSwitchQuest("pre")} />
                        </div>
                    )}
                </Col>
            </Row>
        );
    })

    const PresenterControlInterface = () => {
        if (userRole !== "Presenter")
            return null;
        return (
            <div className="footer">
                <img src={images.value["prev"]} style={{ cursor: 'hand' }} onClick={() => onSwitchQuest("pre")} />
                <img src={images.value["check"]} style={{ cursor: 'hand' }} onClick={() => onStopTheCount()} />
                <div
                    style={{
                        backgroundColor: "#4681cb",
                        borderRadius: 50,
                        width: 80,
                        height: 80,
                        display: "flex",
                        alignContent: "flex-end",
                        justifyContent: "flex-end",
                        alignItems: "flex-end",
                    }}
                >
                    <img src={images.value["icons8_menue"]} style={{ cursor: 'hand', stroke: 'white', fill: 'white' }} onClick={() => showResultScreen()} />
                </div>
                <img src={images.value["next"]} style={{ cursor: 'hand' }} onClick={() => onSwitchQuest("next")} />
            </div>
        )
    }
    const habenArraysSichVeraendert = (arr1, arr2) => {
        // Überprüfe, ob beide Parameter Arrays sind
        if (!Array.isArray(arr1) || !Array.isArray(arr2)) {
            return true;
        }

        // Überprüfe die Länge der Arrays
        if (arr1.length !== arr2.length) {
            return true;
        }

        // Vergleiche die einzelnen Elemente der Arrays
        for (let i = 0; i < arr1.length; i++) {
            if (arr1[i] !== arr2[i]) {
                return true;
            }
        }

        // Überprüfe, ob einer der Parameter zuvor undefined war
        if ((arr1 === undefined && Array.isArray(arr2)) || (Array.isArray(arr1) && arr2 === undefined)) {
            return true;
        }
        console.log("array hat keine verändertung false")
        // Keine Veränderungen festgestellt
        return false;
    }
    useEffect(() => {
        if (!Array.isArray(surveyData.userAnswer)) {
            console.log("setButtonId []")
            setButtonId([]);
        }
        console.log("serveyData hat sich verändrt!!!!!!!!!!!!!!")
    }, [surveyData])

    let {
        id,
        Answers: answers,
        textQuest: QuestionText,
        CurrentQuestNumber,
        BlockAppInput,
        TotalQuestNumber,
        AnswerDecisionInProcent,
        NumberOfClients,
        NumberOfVotes,
    } = surveyData.surveyQuest;
    var isTrivia = false;
    console.log("serveyData", surveyData, answerLogged, buttonId)
    if (answerLogged && (surveyData.blockUserInput === undefined || !BlockAppInput)) {
        setAnswerLogged(false)

        console.log("antwort wird zurückgesetzt!!!!!!!!!!!!!!!!!!", surveyData.userAnswer);
    }
    console.log("arrays", !Array.isArray(surveyData.userAnswer), !Array.isArray(buttonId))

    if (habenArraysSichVeraendert(buttonId, surveyData.userAnswer) && userRole != "Presenter" && surveyData.surveyQuest.Id !== -999 && surveyData.blockUserInput === true) {
        console.log("set button id ", surveyData.userAnswer)
        setButtonId(surveyData.userAnswer);
    }
    // else
    console.log("BlockAppInput", BlockAppInput)
    if (BlockAppInput) {
        var value = answers.find((a) => a.IsCorrect == true);
        if (value !== undefined) {
            try {
                isTrivia = value.IsCorrect;
            } catch (error) {
                console.log(error);
            }
        }
    }
    var disableButton =
        surveyData.blockUserInput ||
        answerLogged ||
        BlockAppInput;
    console.log("disableButton", disableButton, buttonId.length, (disableButton || buttonId.length == 0));
    var buttonColor = (disableButton || buttonId.length === 0) ? "#808080" : "#4681cb";
    var countdownKeyNumber = surveyData.surveyQuest.id;
    if (surveyData.surveyQuest.BlockAppInput) {
        countdownKeyNumber = countdownKeyNumber + 1;
    }
    var buttonFontSize = 0;
    if (window.innerWidth >= 1000) buttonFontSize = window.innerWidth * 0.025;
    else buttonFontSize = window.innerWidth * 0.065;

    // QuestionText = "Was versteht man unter autonomen Fahren (Vollautomatisierung)."
    // var QuestionTextLenght = QuestionText.length;
    // questionTextSize -= Math.ceil(QuestionTextLenght / 100);
    // if (questionTextSize < 10)
    //     questionTextSize = 10;
    // answers.splice(answers.length-2,answers.length)
    var showSurvey = userRole != "Presenter" || surveyData.surveyQuest.Id !== -999;
    if (surveyData.surveyQuest.Id === -999 && answers.length === 0) {
        // console.log("####################################### <<<<<<<<<<<<<<")
        return <>
            {showWhitePage()}
            <Row>
                <Col>
                    {PresenterControlInterface()}
                </Col>
            </Row>
        </>


    }
    return (
        <Row>
            <Col>
                {userRole == "Presenter" && (
                    <div className="counter-user">
                        <h2>
                            {" "}
                            {NumberOfVotes} / {NumberOfClients} aktiv
                        </h2>
                    </div>
                )}
                <div className="content" style={{ marginTop: 15 }}>
                    <div className="counter-question">
                        <h2 style={{ fontSize: 15 }}>
                            Frage {CurrentQuestNumber} / {TotalQuestNumber}
                        </h2>
                    </div>
                    <div className="question" style={{ width: "100%" }}>
                        <div style={{ width: "100%", height: 100 }}>
                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    textAlign: "center",
                                    height: "100%",
                                    width: "100%",
                                }}
                            // min={8}
                            // max={25}
                            // mode="multi"
                            >
                                <strong>{QuestionText}</strong>
                            </div>
                        </div>
                    </div>
                    {surveyCountdown != null && (
                        <div>
                            <h1>
                                <Countdown
                                    key={countdownKeyNumber}
                                    BlockAppInput={BlockAppInput}
                                    questClosed={
                                        surveyData.surveyQuest.BlockAppInput
                                    }
                                    questID={surveyData.surveyQuest.id}
                                    surveyCountdown={surveyCountdown}
                                    maxSurveyCountdownTimer={maxSurveyCountdownTimer}
                                />
                            </h1>
                        </div>
                    )}
                    <div style={{ marginTop: 10 }}>
                        {answers.map((answer, idx) => (
                            <AnswerTemplate
                                onClick={() => onButtonSelected(idx)}
                                selected={buttonId}
                                key={idx}
                                id={idx}
                                bullet={String.fromCharCode(65 + idx)}
                                answer={answer.textAnswer}
                                percentage={AnswerDecisionInProcent}
                                active={active}
                                isTrivia={isTrivia}
                                isAnswerCorrect={answer.IsCorrect}
                            />
                        ))}
                    </div>
                </div>

                {userRole != "Presenter" && (
                    <div
                        style={{
                            display: "flex",
                            alignContent: "center",
                            justifyContent: "center",
                            alignSelf: "center",
                            alignItems: "center",
                            width: "100%",
                            height: 70,
                            marginTop: 15
                        }}
                    >
                        <button
                            disabled={disableButton}
                            onClick={() => onUserLoggedAnswer()}
                            style={{
                                textAlign: "center",
                                backgroundColor: buttonColor,
                                // width: "40%",
                                paddingLeft: '1em',
                                paddingRight: '1em',
                                height: "100%",
                                borderRadius: 2,
                                border: 0,
                                color: "#fff",
                                fontSize: '2em',
                                fontWeight: "bold",
                            }}
                        >
                            Einloggen
                        </button>
                    </div>
                )}
                {PresenterControlInterface()}
            </Col>
        </Row>
    );

    // console.log("Umfrage ", userRole, surveyData, surveyCountdown, maxSurveyCountdownTimer)
    // return null;
}

export default Umfrage;
