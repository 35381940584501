import { useEffect, useState } from "react";
import React from "react";
import Progress from 'react-progressbar';
// var questID = -100;
var newQuest = false;
function Countdown(props) {
    const [counter, setCounter] = useState(0);
    const [questID, setQuestID] = useState(-100);
    const timerSpeed = (100/props.maxSurveyCountdownTimer/10)+(100/props.maxSurveyCountdownTimer/10)*.13;
    const s = ".bg-YOU_PICK_A_NAME{background-color: #123445!important}";
    if (questID != props.questID) {
        // questID = props.QuestID;
        setQuestID(props.questID);
        console.log("props.questClosed",props.questClosed,props.questID)
        if (props.questClosed) {
            setCounter(100);
        } else {
            setCounter(0);
        }
    }
    useEffect(() => {
        console.log("useeffect " , props.questClosed)
        if(props.questClosed){
            return;
        }
        const timer =
            counter < 100 && setInterval(() => counter + timerSpeed > 100 ? setCounter(100) : setCounter(counter + timerSpeed), 100);
        return () => clearInterval(timer);
    }, [counter]);
    return (
        <div style={{ width: '100%', backgroundColor: 'lightgray' }}>
            <Progress style={{ width: '100%' }} color={'#4681cb'} completed={counter} />
        </div>
    );
}

export default Countdown;