import { effect, signal } from "@preact/signals-react";
import config from "../../config";

export const dynamiConfig = signal(null);
export const loadConfig = async () => {
    try {
        const temp = await import(`../../config/${config.appStyle}/dynamiConfig.json`);
        dynamiConfig.value = temp;
    } catch (error) {
        console.error("Fehler: dynamiConfig ", error);
    }
}
effect(() => {
    setTimeout(() => {
        loadConfig();
    }, 200);
}, config.appStyle)

effect(() => {

})
