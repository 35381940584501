import React, { useEffect, useRef, useState } from "react";
import useWebSocket, { ReadyState } from 'react-use-websocket';
import urls from "../config/webURLs.json";
import { IswebsocketConnectionOpen, sendMessage, lastMessage, PasswordIsRight, verifiedUser, LastPresenterCodeAttempt, ShowForAppParameterInURL, showLoadingScreen } from './SignalContextFolder/WebsocketContext.js';
import { withRouter } from "react-router";
import { getParams } from "./SignalContextFolder/URLSearchParamsContext.js";
var setClientFirstTime = false;
var pingIntv = null;

function useStateAndRef(initial) {
    const [value, setValue] = useState(initial);
    const valueRef = useRef(value);
    valueRef.current = value;
    return [value, setValue, valueRef];
}

function Websocket(props) {
    const [webSocket, setWebSocket, webSocketRef] = useStateAndRef(null);

    const OpenConnection = () => {
        // console.log("WebSocket-Verbindung geöffnet");
        setTimeout(() => {
            IswebsocketConnectionOpen.value = true;
        }, 1000);
    };

    const setupWebSocket = () => {
        if (webSocket && webSocket.readyState !== WebSocket.CLOSED) {
            // Wenn eine Verbindung bereits vorhanden ist und nicht geschlossen wurde, schließe sie
            // console.log(" webSocket.close();")
            webSocket.close();
        }

        var room = getParams(props.location.search, "room")
        var url = urls.debugUrl + "Room" + room;
        console.log("url ", url)

        var temp = new WebSocket(url);
        temp.onopen = () => OpenConnection();
        temp.onmessage = (message) => {
            // console.log("message on message ", message.data);
            lastMessage.value = message;
        };
        temp.onclose = (event) => {
            // console.log("WebSocket-Verbindung geschlossen");
            IswebsocketConnectionOpen.value = false;
            PasswordIsRight.value = false;
            verifiedUser.value = undefined;
            LastPresenterCodeAttempt.value = undefined;
            ShowForAppParameterInURL.value = undefined;
            if (event.code !== 1000) {
                // console.log("versuche die verbindung wieder herzustellen")
                // Wenn die Verbindung nicht normal geschlossen wurde, versuche erneut zu verbinden
                setTimeout(setupWebSocket, 5000); // Versuche alle 5 Sekunden erneut
            }
            setTimeout(() => {
                showLoadingScreen.value = false;
            }, 1500);
        };
        temp.onerror = (error) => {
            // alert(error)
            console.error("WebSocket-Fehler:", error);
        };
        // if (temp !== undefined) {
        //     sendMessage.value = temp.send.bind(webSocket); 
        //     // lastMessage.value = temp.lastMessage;
        // }
        sendMessage.value = temp.send.bind(temp);
        setWebSocket(temp);
    };

    useEffect(() => {
        if (!setClientFirstTime) {
            setClientFirstTime = true;
            setupWebSocket();
        }

        // Cleanup
        return () => {
            if (webSocket) {
                webSocket.close();
            }
        };
    }, []);

    useEffect(() => {
        if (pingIntv) clearInterval(pingIntv);
        pingIntv = setInterval(() => {
            if (webSocketRef.current && webSocketRef.current.readyState === WebSocket.OPEN) {
                // Send Ping
                try {
                    webSocketRef.current.send(JSON.stringify({ opcode: -1 }));
                } catch (error) {

                }
            }
        }, 10000);
        return () => {
            clearInterval(pingIntv);
        }
    }, [])

    // useEffect(() => {
    //     // Aktualisiere die Funktionen zum Senden und Empfangen von Nachrichten, wenn sich die WebSocket-Referenz ändert
    //     console.log("set websocket dinge!!")
    //     if (webSocket) {
    //         sendMessage.value = webSocket.send.bind(webSocket); // Hier an binden, da send() im React-Scope nicht funktioniert
    //        // In diesem Beispiel habe ich lastMessage.value auf null gesetzt, da es in der vorherigen Implementierung keine klare Definition dafür gab.
    //         // webSocket.onmessage = (message) => {
    //         //     console.log("message on message ", message.data);

    //         //     // lastMessage.value = message.data;
    //         // };
    //     }
    // }, [webSocket]);

    return null; // Kein JSX-Element zurückgeben, da dieser Hook nur eine Funktion hat
}

export default withRouter(Websocket);

// export default function WebsocketWrapper() {
//     Websocket(); // Aufruf der benutzerdefinierten Hook-Funktion
//     return null; // Kein JSX-Element zurückgeben, da dieser Komponente nur eine Funktion hat
// }
