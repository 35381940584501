// import React, { Component } from "react";
import React, { useState } from 'react';
export default function ME_Template(data) {
    const props = data.parantData;
    const { active, isTrivia, isAnswerCorrect } = props;
    const percentage = props.percentage == undefined ? undefined : props.percentage[props.id];
    const onClick = props.onClick;
    const selected = props.selected

    const getBulletStyle = () => {
        let base = {};      //Aussehen = btn-gears
        if (isTrivia) {
            if (isAnswerCorrect) {
                base = { background: 'linear-gradient(225.72deg, #008716 0%, #1efe00 100%)' };
            }
        }
        return base;
    }

    const getEntireAnswerBox = (id) => {
        var abstand = 12;
        let base = { marginBottom: abstand };
        if (Array.isArray(selected))
            if (selected.includes(id))
                base = {
                    boxShadow: "0px 0px 5px 6px #0379A4",
                    marginBottom: abstand
                };
        return base;
    }

    let prcntClass = 'percentage';
    if (active) prcntClass += ' active';

    const extraClass = props.answer.length > 80 ? "small" : "";

    return (
        <div className="answer-item" style={getEntireAnswerBox(props.id)} onClick={() => onClick(props.id)}>
            <div className='answer-progressbar'>
                <div className={prcntClass} style={{ width: percentage }}> </div>
            </div>
            <div className="answer-content">
                <div className="answer-content-left">
                    <span className='bulletpoint' style={getBulletStyle()}>{props.bullet}</span>
                    <div className={'answer ' + extraClass}>
                        {props.answer}
                    </div>
                </div>
                <span className='answer-percentage'>{percentage}</span>
            </div>
        </div>

    )
}
