import React from "react";
import { Row, Col, Button } from "react-bootstrap";
import { IswebsocketConnectionOpen, sendMessage } from "../../websocket/SignalContextFolder/WebsocketContext";
import { allStylesLoaded } from "../../websocket/SignalContextFolder/CssContext";

function RoomNotFoundClass() {
    if (!allStylesLoaded.value) return null;
    return (
        <Row>
            <Col>
                <div className="pause">
                    <h2>
                        Raumnummer konnte nicht gefunden werden
                        {/* <Button onClick={() => debugAlert()}> Debug </Button> */}
                    </h2>
                    <p>Frag nach einem passenden Link oder QR-Code</p>
                </div>
            </Col>
        </Row>
    );
}

export default RoomNotFoundClass;
