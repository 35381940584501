import React, { useEffect, useState } from "react";
import { withRouter } from "react-router";
import { sendMessage } from "../websocket/SignalContextFolder/WebsocketContext";
import Header from "../shared/Header";
import { Button, Col, Form, Row } from "react-bootstrap";
import { getStorage, setStorage } from "./StoreData";
var tryOneTime = true;
const UserLogin = (props) => {

    const [password, setPasswort] = useState("");
    const [text, setText] = useState("");

    useEffect(() => {
        setTimeout(() => {
            if (tryOneTime) {
                var isPasswordRight = getStorage("roompassword");
                tryOneTime = !tryOneTime;
                sendPassword(isPasswordRight, false)
            }
        }, 500);
    }, [])
    const sendPassword = (password, setThePassword) => {
        try {
            setTimeout(() => {
                try {
                    sendMessage.value(JSON.stringify({ opcode: 0, payload: password }));
                    setStorage("roompassword", password, null);
                    // console.log("sende passwort");
                    // console.log("set password", password);
                    setTimeout(() => {
                        if (setThePassword) setPasswort(password);
                        setTimeout(() => {
                            if (setThePassword) setPasswort("");
                        }, 6000);
                    }, 2000);
                } catch (error) { }
            }, 500);
        } catch (error) {
            console.log("error " + error);
        }
    };
    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            sendPassword(text, true)
        }
    }
    return (
        <div>
            <Header content={"anmeldung"} />
            <Row>
                <Col>
                    <div className="pause">
                        <h2>Gib das Passwort ein, um am Stream teilzunehmen.</h2>
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "center",
                                alignItems: "center",
                            }}
                        >
                            <Form.Control
                                type="text"
                                maxLength="5"
                                autoComplete="off"
                                placeholder="Gib hier das Passwort ein."
                                value={text}
                                onChange={(e) => setText(e.target.value.toUpperCase())}
                                onKeyDown={handleKeyDown}
                            />
                            <Button
                                className="inverted"
                                onClick={() => sendPassword(text, true)}
                            >
                                {" "}
                                Senden
                            </Button>
                        </div>
                        {password && (
                            <div style={{ marginTop: 15 }}>
                                Das eingegebene Passwort ist leider ungülig.
                            </div>
                        )}
                    </div>
                </Col>
            </Row>
        </div>
    );
};
export default withRouter(UserLogin);