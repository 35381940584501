import React from 'react';
import Pausenbild from './pages/Pausenbild';

import Picture from './pages/Picture';
import Movie from './pages/Movie';
import { UserRole } from '../websocket/SignalContextFolder/WebsocketContext';
import Umfrage from './pages/Umfrage';
import UmfrageResult from './pages/UmfrageResult';
// import NewMovie from './pages/NewMovie';
// import MintNavi from '../pages/MintNavi';
// import Movie from '../pages/Movie';
// import Picture from '../pages/Picture';
// import Umfrage from './pages/UmfrageTemplate';

// import Anmeldung from '../pages/Anmeldung';
// import UmfrageResult from '../pages/UmfrageResult';

var keyindex = 0;

// const isTheSameModul = (props) => {
//     return props.lastmessage.Content == props.content && props.lastmessage.title == props.title;
// }
// if (!isTheSameModul(props)) {
//     keyindex++;
//     //  console.log("keyindex ", keyindex);
// }
export default function Switch({ data, userRole, sendMessage }) {


    // console.log("Switch wurde aufgerufen: " + isTheSameModul(props ));
    // console.log("switch content", props.lastmessage.Content , " " , props.content);
    // console.log("switch title", props.lastmessage.title , " " , props.title);
    switch (data.Content) {
        case "picture":
            // key={keyindex} title={props.title} picture={props.picture} caption={props.caption} userRole={props.userRole} bookmarkId={props.bookmarkId}
            return <Picture />
            break;
        case "movie":
            return <Movie data={data} sendMessage={sendMessage} />
            // return <Movie key={keyindex} title={props.title} mov={props.mov} caption={props.caption} userRole={props.userRole} bookmarkId={props.bookmarkId} />
            break;
        case "pause":
            return <Pausenbild />
            break;
        // case "MintNavi":
        //     return <MintNavi userRole={props.userRole} />
        //     break;
        // case "anmeldung":
        //     return <Anmeldung code={props.code} userRole={props.userRole} />
        //     break;
        case "umfrage":
            return <Umfrage userRole={UserRole.value} surveyData={data.surveyData} surveyCountdown={data.surveyCountdown} maxSurveyCountdownTimer={data.maxSurveyCountdownTimer} />
        //     break;
        case "result":
            return <UmfrageResult userRole={UserRole.value} surveyData={data.surveyData} />
            break;
        default:
            return null;
            break;
    }
}