import React from "react";
import { Row, Col, Button } from "react-bootstrap";
import { IswebsocketConnectionOpen, sendMessage } from "../../websocket/SignalContextFolder/WebsocketContext";
import { allStylesLoaded } from "../../websocket/SignalContextFolder/CssContext";
import Header from "../../shared/Header";

function Pausenbild() {
  if (!allStylesLoaded.value) return null;

 return null;
}

export default Pausenbild;
