
const loadImage = async (config, formats, imageName) => {
  const loadedImages = {};

  for (const format of formats) {
    try {
      const imageModule = await import(`../style/images/${config.appStyle}/${format}/${imageName}.${format}`);
      const image = new Image();
      image.src = imageModule.default;
      await image.decode();
      loadedImages[imageName] = image.src;
    } catch (error) {
      // console.error(`Fehler beim Laden von ${format}-Bild ${imageName}: ${error.message}`);
    }
  }

  return loadedImages;
};

const loadImages = async (config, imageConfig) => {
  const loadedImages = {};

  // Define image formats and their corresponding image lists
  for (const [key, value] of Object.entries(imageConfig)) {
    const imageName = value;
    if (key === "default")
      continue;
    if (!loadedImages[imageName]) {
      // Define supported formats for this image
      const formats = ['jpg', 'svg', 'png']; // Add more formats if needed

      // Load image with different formats
      const result = await loadImage(config, formats, imageName);
      if (Object.keys(result).length > 0) {
        Object.assign(loadedImages, result); // Merge loaded image into loadedImages
      }
    }
  }
  return loadedImages;
};


// const loadJpgImages = async (config) => {
//   const loadedImages = {};

//   try {


//     for (const imageName of config.images) {
//       const imageModule = await import(`../style/images/${config.appStyle}/jpg/${imageName}.jpg`);
//       const image = new Image();
//       image.src = imageModule.default;
//       await image.decode();
//       loadedImages[imageName] = image.src;
//     }

//     return loadedImages;
//   } catch (error) {
//     throw new Error(`Fehler beim Laden der Bilder: ${error.message}`);
//   }
// };

// const loadSvgImages = async (config) => {
//   const loadedSvg = {};

//   for (const imageName of config.svg) {
//     const svgModule = await import(`../style/images/${config.appStyle}/svg/${imageName}.svg`);
//     const svgImage = new Image();
//     svgImage.src = svgModule.default;
//     await svgImage.decode();
//     loadedSvg[imageName] = svgImage.src;
//   }

//   return loadedSvg;
// };

// const loadPngImages = async (config) => {
//   const loadedSvg = {};

//   for (const imageName of config.png) {
//     const svgModule = await import(`../style/images/${config.appStyle}/png/${imageName}.png`);
//     const svgImage = new Image();
//     svgImage.src = svgModule.default;
//     await svgImage.decode();
//     loadedSvg[imageName] = svgImage.src;
//   }

//   return loadedSvg;
// };

export { loadImages };