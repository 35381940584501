// App.js
import React, { useEffect, useState } from "react";
import PresenterLogin from "./PresenterLogin";
import UserLogin from "./UserLogin";
import { PasswordIsRight, ShowForAppParameterInURL, sendMessage, showLoadingScreen } from "../websocket/SignalContextFolder/WebsocketContext";
import Anmeldung from "./Anmeldung";
import LoginAsAppUser from "./LoginAsAppUser";
import { getStorage } from "./StoreData";
import { Col, Row } from "react-bootstrap";

const LoadingScreen = () => {

    useEffect(() => {
        var password = getStorage("roompassword");
        if (password != null) {
            sendMessage.value(
                JSON.stringify({ opcode: 0, payload: password })
            );
        } else {
            setTimeout(() => {
                showLoadingScreen.value = false;
            }, 1000);
        }

    }, [])


    return (
        <div>
            {/* <Header content={"anmeldung"} /> */}
            <Row>
                <Col>
                    <div className="pause">
                        <h2>
                            Es geht gleich weiter. Einen Augenblick!
                            {/* <Button onClick={() => debugAlert()}> Debug </Button> */}
                        </h2>
                    </div>
                </Col>
            </Row>
        </div>
    );
    // return <div style={{backgroundColor:"red", width:'100%', height:'100%'}}>loading</div>

};

export default LoadingScreen;