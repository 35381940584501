// App.js
import React, { useEffect, useState } from "react";


import Websocket from "./websocket/Websocket";
import { useStateContext } from "./websocket/StateContext";
import Login from "./login/Login";
import { PasswordIsRight, UserRole, verifiedUser, UserId, sendMessage } from "./websocket/SignalContextFolder/WebsocketContext";
import Switch from "./switch/Switch";
import Header from "./shared/Header";
import { DataFromServer } from '../src/websocket/SignalContextFolder/WebsocketContext';
import Chat from "./chat/Chat";
import { Container } from "react-bootstrap";
import { getStorage } from "./login/StoreData";

const AfterLoad = () => {
    if (verifiedUser.value === undefined)
        return <Login />;

    var data = DataFromServer.value;
    if (data === undefined || data.Content === undefined)
        return null;
    let cn = "home-wrapper";
    if (data.Content == "MintNavi") cn += " mint-navi-active";

    return <div className="app-wrapper">
        <div className={cn}>
            {data.Content !== "x" && (
                <Header
                    code={UserId.value}
                    content={data.Content}
                    userRole={UserRole.value}
                    group={data.group}
                    title={data.title}
                />
            )}
            {data.Content === "MintNavi" ? (
                <> {renderSwitch(data)}</>
            ) : (
                <Container fluid="xl">{renderSwitch(data)}</Container>
            )}

            <Chat userRole={UserRole.value}></Chat>
        </div>
    </div>
};

const renderSwitch = (data) => {
    return (
        <Switch data={data} userRole={UserRole.value} sendMessage={sendMessage.value}/>
    );
};



export default AfterLoad;