const loadCss = async (config) => {
    // Dynamisches Importieren von CSS
    import(`../style/css/${config.appStyle}/styles.scss`)
      .then(() => {
      })
      .catch((error) => {
        console.error("Fehler beim Laden des CSS:", error);
      });
};

export default loadCss;