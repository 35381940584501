import { computed, effect, signal } from "@preact/signals-react";
import config from "../../config";

export const roomNotFound = signal(true);
// computed(() => {

// })


export const getParams = (searchLocation, name) => {
    const query = new URLSearchParams(searchLocation);
    let value = query.get(name);
    return value;
}

