import React, { useState, useEffect } from "react";
import { Row, Col, Form, Button, Container } from "react-bootstrap";
import Header from "../shared/Header";
import { GetCode, PostUserId_Nickname } from "../login/IkoManager";
import { getStorage, setStorage, removeStorage } from "../login/StoreData";
import { images } from "../websocket/SignalContextFolder/CssContext";
import { dynamiConfig } from "../websocket/SignalContextFolder/ConfigContext";
import { Userid, setUserDataToServer as sendUserDataToServer } from "../websocket/SignalContextFolder/WebsocketContext";
import { signal } from "@preact/signals-react";
import config from "../config";
import { withRouter } from "react-router-dom";
// dynamiConfig
const userId = signal(undefined);
var newUserId = true;
function Anmeldung(props) {
  const [nickName, setNickName] = useState("");
  var pcStoreUserId = getStorage("ikoCode");

  if ((pcStoreUserId === undefined || pcStoreUserId === null) && newUserId) {
    newUserId = false;
    const UserIdFunction = async () => {
      pcStoreUserId = await GetCode(dynamiConfig.value);
      console.log("pcStoreUserId", pcStoreUserId)
      userId.value = pcStoreUserId;
    };
    UserIdFunction();
  }

  const BestaetigungsFunc = () => {
    const randomString = () => {
      const alphabet = 'abcdefghijklmnopqrstuvwxyz';
      let result = '';
      for (let i = 0; i < 4; i++) {
        const randomIndex = Math.floor(Math.random() * alphabet.length);
        result += alphabet[randomIndex];
      }
      return result;
    }

    const UserIdFunction = async () => {
      try {
        // console.log(userId.value,nickName,dynamiConfig.value)
        // return;
        var answer = await PostUserId_Nickname(dynamiConfig.value, userId.value, nickName);
        // var answer = {};
        // answer.ID = "T:" + randomString();
        console.log("hier >>>>>>>>", answer)
        if (config.appStyle === "tt") {
          setStorage("nickName", nickName, null);
          setStorage("userId", answer.id, null);
          setStorage("ikoCode", answer.ikoCode, null);
          sendUserDataToServer(answer.ikoCode, nickName);
        }
        else {
          setStorage("nickName", nickName, null);
          setStorage("userId", answer.ID, null);
          setStorage("ikoCode", answer.ID, null);
          sendUserDataToServer(answer.ID, nickName);
        }
        window.location.reload(false);
        // setStorage("userId", userId.value, null);

      } catch (error) {
        console.log("Error", error);
      }
    };
    UserIdFunction();
  };

  const renderUserId = () => {
    if (userId.value === null) {
      return <span className="special">...</span>;
    }
    return <span className="special">{userId.value}</span>;
  };

  const query = new URLSearchParams(props.location.search);
  let room = query.get("room");

  return (
    <div className="app-wrapper-login">
      <Header content={"anmeldung"} />
      <Container fluid="xl">
        {props.userRole == "Presenter" && (
          <div>
            <h3>
              Während des Streams kannst Du Inhalte speichern, die Dich interessieren.
              Diese kannst Du später in der ME-Berufe App wieder aufrufen.
              Dort erwarten Dich dann noch viele weitere Infos und spannende Challenges.
            </h3>
            <h2>
              Scanne den QR Code mit deiner ME-Berufe App, einem QR-Scanner, oder geben den Link in deinem Browser ein.
            </h2>
            <img src={"https://service-stories.meberufe.net/stations/streamqr/" + room} style={{ width: 250, height: 250 }} />
            <h3>
              me-webinar.rheinsitemedia.net
            </h3>
          </div>
        )}
        {props.userRole != "Presenter" && (
          <div>
            <h2>Herzlich Willkommen beim ME-Berufe-Stream!</h2>
            <br />
            <p>
              Während des Streams kannst Du Inhalte speichern, die Dich interessieren.
              Diese kannst Du später in der ME-Berufe App wieder aufrufen.
              Dort erwarten Dich dann noch viele weitere Infos und spannende Challenges.
            </p>
            <div className="step-container">
              <div className="step">
                <div className="num-circle">1</div>
                <p>Bevor es lostgeht, bekommst Du von uns einen persönlichen Login-Code:</p>
              </div>
              <div className="code-inline step-offset">Dein Code: {renderUserId()}</div>
              <div className="step">
                <div className="num-circle">2</div>
                <p>Vergib Dir nun noch einen Nickname und bestätige die Eingabe.</p>
              </div>
              <div className="step-offset">
                <Form.Control
                  type="nickname"
                  placeholder="Dein Nickname (max.15 Zeichen)"
                  value={nickName}
                  maxLength="15"
                  pattern="[A-Z]*"
                  onChange={(event) => setNickName(event.target.value.toUpperCase())}
                />
                <Form.Text className="text-muted">
                  Maximal 15 Zeichen
                </Form.Text>
              </div>
            </div>
            <div className="nav">
              <Button variant="primary" disabled={nickName.length === 0} type="submit" onClick={() => BestaetigungsFunc()}>
                Bestätigen
              </Button>
            </div>
          </div>
        )}
      </Container>
    </div>
  );
}

export default withRouter(Anmeldung);
