// App.js
import React, { useEffect, useState } from "react";
import PresenterLogin from "./PresenterLogin";
import UserLogin from "./UserLogin";
import { PasswordIsRight, ShowForAppParameterInURL, showLoadingScreen } from "../websocket/SignalContextFolder/WebsocketContext";
import Anmeldung from "./Anmeldung";
import LoginAsAppUser from "./LoginAsAppUser";
import LoadingScreen from "./LoadingScreen";

const Login = () => {

    if ((PasswordIsRight.value && PasswordIsRight.value === true) && ShowForAppParameterInURL.value === undefined) {
        return <LoginAsAppUser />
    }

    if (PasswordIsRight.value && PasswordIsRight.value === true)
        return <Anmeldung />


    return <div className="app-wrapper">
        <div className="home-wrapper">
            {
                (showLoadingScreen.value === undefined || showLoadingScreen.value === true) &&
                <LoadingScreen></LoadingScreen>
                ||
                <div>
                    <PresenterLogin />
                    <UserLogin />
                </div>
            }
        </div>
    </div>
};

export default Login;