import { effect, signal } from "@preact/signals-react";
import loadCss from "../../loadFiles/loadCss";
import config from "../../config";
import { loadJpgImages, loadSvgImages, loadPngImages, loadImages } from "../../loadFiles/loadImages";

export const images = signal({});
const imagesMapping = signal({});
export const cssStyleLoaded = signal(false);
export const jpgImagesLoaded = signal(false);
export const svgImagesLoaded = signal(false);
export const pngImagesLoaded = signal(false);
export const loadAllImages = signal(false);
const mappingImages = signal(false);

export const allStylesLoaded = signal(false);
export const loadFiles = async () => {
    var imageConfig = await import(`../../config/${config.appStyle}/imagesMap.json`);

    loadCss(config)
        .then(() => {
            cssStyleLoaded.value = true;
        })
        .catch((error) => {
            console.error("Fehler beim Laden des CSS:", error);
        });

    loadImages(config, imageConfig)
        .then((loadedImages) => {
            imagesMapping.value = { ...imagesMapping.value, ...loadedImages };
            loadAllImages.value = true;
        })
        .catch((error) => {
            console.error("Fehler beim Laden aller Bilder:", error);
        });

    // loadJpgImages(config)
    //     .then((loadedImages) => {
    //         imagesMapping.value = { ...imagesMapping.value, ...loadedImages };
    //         jpgImagesLoaded.value = true;
    //     })
    //     .catch((error) => {
    //         console.error("Fehler beim Laden der jpg Bilder:", error);
    //     });

    // loadSvgImages(config)
    //     .then((loadedImages) => {
    //         imagesMapping.value = { ...imagesMapping.value, ...loadedImages };
    //         svgImagesLoaded.value = true;
    //     })
    //     .catch((error) => {
    //         console.error("Fehler beim Laden der svg Bilder:", error);
    //     });
    // loadPngImages(config)
    //     .then((loadedImages) => {
    //         imagesMapping.value = { ...imagesMapping.value, ...loadedImages };
    //         pngImagesLoaded.value = true;
    //     })
    //     .catch((error) => {
    //         console.error("Fehler beim Laden der svg Bilder:", error);
    //     });
}

const mappingTheImages = async () => {
    const mappingConfig = await import(`../../config/${config.appStyle}/imagesMap.json`);
    images.value["logo"] = imagesMapping.value[mappingConfig.logo];
    images.value["biglogo"] = imagesMapping.value[mappingConfig.biglogo];
    images.value["icon_white"] = imagesMapping.value[mappingConfig.iconWhite];
    images.value["close"] = imagesMapping.value[mappingConfig.close];
    images.value["prev"] = imagesMapping.value[mappingConfig.prev];
    images.value["next"] = imagesMapping.value[mappingConfig.next];
    images.value["check"] = imagesMapping.value[mappingConfig.check];
    images.value["icons8_menue"] = imagesMapping.value[mappingConfig.icons8_menue];
    images.value["Anmeldung_QR"] = imagesMapping.value[mappingConfig.Anmeldung_QR];

    mappingImages.value = true;
}

effect(() => {
    setTimeout(() => {
        loadFiles();
    }, 200);
}, config.appStyle)

effect(() => {
    // if (!jpgImagesLoaded.value)
    //     return;
    // if (!svgImagesLoaded.value)
    //     return;
    // if (!pngImagesLoaded.value)
    //     return;
    if (!loadAllImages.value)
        return;

    mappingTheImages();

})

effect(() => {
    // if (!cssStyleLoaded.value)
    //     return;
    // if (!jpgImagesLoaded.value)
    //     return;
    // if (!svgImagesLoaded.value)
    //     return;
    if (!mappingImages.value)
        return;

    allStylesLoaded.value = true;
})
